import { MouseEventHandler } from 'react';
import classNames from 'classnames';
import CustomLink from '@components/CustomLink';
import Image from 'next/image';

import Progress from './Progress';
import s from './Button.module.scss';

type BaseProps = {
  disabled?: boolean;
  loading?: boolean;
  variant: 'primary' | 'secondary' | 'destructive' | 'attention' | 'ai';
  size: 'xs' | 'sm' | 'md' | 'lg';
  fullWidth?: boolean;
  className?: string;
};

type ButtonProps =
  | {
      href?: never;
      onClick: MouseEventHandler<HTMLButtonElement>;
      type: 'button';
    }
  | {
      href?: never;
      onClick?: MouseEventHandler<HTMLButtonElement>;
      type?: 'submit' | 'reset';
    };

type LinkProps = {
  href: string;
  onClick?: never;
  type?: never;
};

type Props = BaseProps & (ButtonProps | LinkProps);

const Button: React.FC<Props> = ({
  children,
  className,
  disabled = false,
  loading,
  variant = 'primary',
  size = 'md',
  onClick,
  href,
  fullWidth,
  type = 'button',
}) => {
  const wrapperClass = classNames(
    s.wrapper,
    variant === 'primary' && s.primary,
    variant === 'secondary' && s.secondary,
    variant === 'destructive' && s.destructive,
    variant === 'attention' && s.attention,
    variant === 'ai' && s.ai,
    size === 'xs' && s.xs,
    size === 'sm' && s.sm,
    size === 'md' && s.md,
    size === 'lg' && s.lg,
    loading && s.loading,
    fullWidth && s.fullWidth,
    className,
  );

  if (href) {
    return (
      <CustomLink className={wrapperClass} to={href}>
        {loading && <Progress size={size} />}
        {children}
      </CustomLink>
    );
  }

  return (
    <button
      className={wrapperClass}
      disabled={disabled || loading}
      type={type}
      onClick={onClick}
    >
      {variant === 'ai' && (
        <>
          <div className={s.aiBorder} />
          <div className={s.aiBg} />
        </>
      )}
      {loading && <Progress size={size} />}
      <span className={classNames(variant === 'ai' ? s.aiChildren : s.contents)}>
        {variant === 'ai' && <Image src='/images/dashboard/ai-icon.webp' alt='' width={20} height={20} />}
        {children}
      </span>
    </button>
  );
};

export default Button;
