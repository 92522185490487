import type { NoRequiredParams, RequiredParams } from './typings';

export enum IDENTIFIERS {
  ADMIN_AFFILIATE_LIST = '/admin/affiliate',
  ADMIN_CREATORS = '/admin/creators',
  ADMIN_CREATORS_SLUG = '/admin/creators/[creatorSlug]',
  ADMIN_FAQS_LIST = '/admin/faq',
  ADMIN_PRODUCTS_LIST = '/admin/products',
  ADMIN_PRODUCTS_TYPES = '/admin/product-types',
  ADMIN_SIGN_IN = '/admin/sign-in',
  ADMIN_TAGS_LIST = '/admin/tags',
  ADMIN_TESTIMONIALS_LIST = '/admin/testimonials',

  AGENCY_DASHBOARD = '/agency-dashboard',
  AGENCY_DASHBOARD_TEAM = '/agency-dashboard/team',
  AGENCY_ONBOARDING = '/agency-onboarding',

  AUTH_FORGOT_PASSWORD = '/auth/forgot-password',
  AUTH_RESET_PASSWORD = '/auth/reset-password',
  AUTH_SIGN_IN = '/auth/sign-in',
  AUTH_SIGN_UP = '/auth/sign-up',

  BROADCAST_LIBRARY = '/[creatorSlug]/broadcast-library',
  CART = '/[creatorSlug]/cart',
  CART_CHECKOUT = '/[creatorSlug]/cart/checkout',
  CHAT_WITH_CREATOR = '/chat-with/[creatorSlug]',
  COLLECTION = '/[creatorSlug]/collection/[collectionSlug]',
  COMPONENTS = '/components',
  CONNECT = '/[creatorSlug]/connect',
  CONVERSATION_SID = '/conversation/[conversationSid]',
  CREATOR = '/[creatorSlug]',
  CREATOR_ONBOARDING_ATTORNEY = '/attorney-onboarding',
  CREATOR_ONBOARDING_CLAIM_SLUG = '/claim-slug',
  CREATOR_ONBOARDING_START_INITIAL_PRODUCT_CREATION = '/onboarding/start-initial-product-creation',
  CREATOR_ONBOARDING_AI_AGENT_SETUP = '/onboarding/ai-agent-setup',
  CREATOR_ONBOARDING_TERMS_OF_SERVICE = '/onboarding/terms-of-service',
  CREATOR_ONBOARDING_VERIFY_URL = '/verify-noodle-url',
  CREATOR_ONBOARDING_IN_PERSON_SESSIONS = '/onboarding/in-person-sessions',
  CREATOR_ONBOARDING_ONLINE_SESSIONS = '/onboarding/online-sessions',
  CREATOR_ONBOARDING_CHAT = '/onboarding/chat',
  CREATOR_ONBOARDING_MEMBERSHIP = '/onboarding/membership',
  CREATOR_ONBOARDING_CREATE_SERVICE = '/onboarding/create-service',
  CREATOR_ONBOARDING_CREATE_PRICE = '/onboarding/create-price',
  CREATOR_ONBOARDING_CREATE_TIER = '/onboarding/create-tier',
  CREATOR_ONBOARDING_SUCCESS = '/onboarding/success',
  CREATOR_ONBOARDING_SELECT_WORKFLOWS = '/onboarding/select-workflows',
  CREATOR_ONBOARDING_BOOK_CALL = '/onboarding/book-call',

  CREATOR_SIGN_IN = '/creator/sign-in',
  CREATOR_SIGN_UP = '/creator/sign-up',
  CREATOR_STORIES = '/creator-stories',
  CREATOR_STORY_SLUG = '/creator-stories/[creatorSlug]',
  CREATOR_TERMS = 'https://docs.google.com/document/d/13dlK3lLofJXIunniN9Bx0UfWIkCR9VC4s4PmLEicIII/edit?usp=sharing',
  CREATOR_POPOVER_WIDGET_HOME = '/popover-widget/[creatorSlug]/main',

  DASHBOARD_AI_FOLLOWUP_HISTORY = '/dashboard/ai-followup-history',
  DASHBOARD_CHAT_WITH_CREATOR = '/dashboard/chat-with/[creatorSlug]',
  DASHBOARD_EDIT_TIER = '/dashboard/membership/tiers/[tierId]',
  DASHBOARD = '/dashboard',
  DASHBOARD_CONVERSATIONS = '/dashboard/conversations',
  DASHBOARD_CONVERSATION = '/dashboard/conversations/[conversationSid]',
  DASHBOARD_COMMENTS = '/dashboard/comments',
  DASHBOARD_COMMENT_DETAILS = '/dashboard/comments/[id]',
  DASHBOARD_BYTES = '/dashboard/bytes',
  DASHBOARD_BROADCASTS = '/dashboard/broadcasts',
  DASHBOARD_BROADCAST_DETAILS = '/dashboard/broadcasts/[broadcastId]',
  DASHBOARD_BROADCAST_CREATE = '/dashboard/broadcasts/create',
  DASHBOARD_WIDGET = '/dashboard/widget',
  DASHBOARD_MEMBERSHIP = '/dashboard/membership',
  DASHBOARD_MEMBERS = '/dashboard/members',
  DASHBOARD_SCRIPTS = '/dashboard/scripts',
  DASHBOARD_WORKFLOWS_TEMPLATES = '/dashboard/workflows/templates',
  DASHBOARD_MESSAGING_FEED = '/dashboard/messaging/feed',
  DASHBOARD_IMPORT_CONTACTS = '/dashboard/import-contacts',
  DASHBOARD_MEMBER = '/dashboard/members/[id]',
  DASHBOARD_SCRIPT = '/dashboard/scripts/[id]',
  DASHBOARD_WORKFLOWS_TEMPLATES_DETAIL = '/dashboard/workflows/templates/[id]',
  DASHBOARD_WORKFLOWS_WORKFLOW_TRACKER = '/dashboard/workflows/workflow-tracker',
  DASHBOARD_PREVIEW = '/dashboard/preview',
  DASHBOARD_PRODUCT_TERMS = '/dashboard/custom-terms/[productId]',
  DASHBOARD_SERVICES = '/dashboard/services',
  DASHBOARD_CONTENT = '/dashboard/content',
  DASHBOARD_SERVICES_DETAILS = '/dashboard/services/[productSlug]',
  DASHBOARD_TERMS_TEMPLATES = '/dashboard/terms-templates',
  DASHBOARD_TERMS_TEMPLATES_EDIT = '/dashboard/terms-templates/[id]',
  DASHBOARD_DOCUMENT_REQUEST_TEMPLATES = '/dashboard/document-request-templates',
  DASHBOARD_DOCUMENT_REQUEST_TEMPLATES_EDIT = '/dashboard/document-request-templates/[id]',
  DASHBOARD_FORM_REQUESTS = '/dashboard/form-requests',
  DASHBOARD_FORM_REQUESTS_EDIT = '/dashboard/form-requests/[id]',
  DASHBOARD_QUESTIONNAIRES = '/dashboard/questionnaires',
  DASHBOARD_QUESTIONNAIRES_EDIT = '/dashboard/questionnaires/[id]',
  DASHBOARD_INVOICE_REQUESTS = '/dashboard/invoice-requests',
  DASHBOARD_INVOICE_REQUESTS_EDIT = '/dashboard/invoice-requests/[id]',
  DASHBOARD_ACCOUNT = '/dashboard/account',
  DASHBOARD_CHATS_LIST = '/dashboard/chats/list',
  DASHBOARD_TEAM = '/dashboard/account/team',
  DASHBOARD_WORKFLOWS = '/dashboard/workflows',
  DASHBOARD_INBOX = '/dashboard/inbox',
  DASHBOARD_INBOX_ITEM = '/dashboard/inbox/[id]',
  DASHBOARD_BOOKINGS = '/dashboard/bookings',
  DASHBOARD_BOOKINGS_ITEM = '/dashboard/bookings/[id]',
  DASHBOARD_WORKFLOWS_ATTACHMENTS = '/dashboard/workflows/attachments',
  DASHBOARD_WORKFLOWS_MY_WORKFLOWS = '/dashboard/workflows/my-workflows',
  DASHBOARD_WORKFLOWS_MY_WORKFLOWS_DETAILS = '/dashboard/workflows/my-workflows-v2/[workflowId]',
  DASHBOARD_WORKFLOWS_MY_WORKFLOWS_PRODUCT = '/dashboard/workflows/my-workflows/[productId]',
  DASHBOARD_USER_WORKFLOWS = '/dashboard/workflows/user-workflow',
  DASHBOARD_USER_WORKFLOW_DETAIL = '/dashboard/workflows/user-workflow/[userWorkflowId]',
  DASHBOARD_USER_WORKFLOW_DOCUMENTS = '/dashboard/workflows/user-workflow/[userWorkflowId]/documents',

  DASHBOARD_CHAT_PRODUCT_SETTINGS = '/dashboard/chats/[productSlug]/settings',
  DASHBOARD_ADD_WELCOME_TEXT = '/dashboard/[productId]/add-welcome-text',
  DASHBOARD_ADD_TAGS = '/dashboard/[productId]/add-tags',
  DASHBOARD_EXAMPLES = '/dashboard/noodle-stars',
  DASHBOARD_TRANSACTIONS = '/dashboard/transactions',
  DASHBOARD_TRANSACTIONS_PAYMENTS = '/dashboard/transactions/payments',
  DASHBOARD_TRANSACTIONS_INVOICES = '/dashboard/transactions/invoices',
  DASHBOARD_TRANSACTIONS_PAYOUTS = '/dashboard/transactions/payouts',
  DASHBOARD_TRANSACTIONS_OVERVIEW = '/dashboard/transactions/overview',

  DASHBOARD_ACCOUNT_BILLING = '/dashboard/account/billing',
  DASHBOARD_ACCOUNT_SMS_FOLLOW_UPS = '/dashboard/account/sms-follow-ups',
  DASHBOARD_ACCOUNT_EDIT_PROFILE = '/dashboard/account/edit-profile',
  DASHBOARD_ACCOUNT_ATTORNEY_INFO = '/dashboard/account/attorney-information',
  DASHBOARD_ACCOUNT_EDIT_BUSINESS_DETAILS = '/dashboard/account/edit-business-details',
  DASHBOARD_ACCOUNT_AI_AGENT = '/dashboard/account/ai-agent',
  DASHBOARD_ACCOUNT_INTEGRATIONS = '/dashboard/account/integrations',

  DOCUMENT_BY_ID = '/[creatorSlug]/documents/[documentId]',
  DOCUMENT_REQUEST_USER_DETAIL = '/[creatorSlug]/document-requests/[documentRequestUserId]',
  DOCUMENT_REQUEST_USER_FILE_DETAIL = '/[creatorSlug]/document-requests/[documentRequestUserId]/document/[documentRequestUserFileId]',
  DOCUMENT_REQUEST_DOCUMENT_PREVIEW = '/[creatorSlug]/document-requests/documents/[documentRequestUserFileId]',

  FORM_REQUEST_DETAILS = '/[creatorSlug]/form-requests/[formRequestId]',
  INVOICE_DETAIL = '/[creatorSlug]/invoices/[invoiceId]',
  PAY_INVOICE = '/[creatorSlug]/invoices/[invoiceId]/pay',

  CUSTOM_TERMS_DETAILS = '/[creatorSlug]/custom-terms/[customTermsId]',

  HELP = '/help',
  HOME = '/',
  LOGIN = '/auth/login',
  PAGE_401 = '/401',
  LOGOUT = '/auth/logout',
  PRIVACY = '/privacy-policy',
  PRODUCT = '/[creatorSlug]/[productSlug]', // old version
  PRODUCT_TYPE = '/[creatorSlug]/product-type/[productTypeSlug]',
  INITIATE_WORKFLOW_WITH_CTA = '/[creatorSlug]/initiate-workflow/[stepId]',
  PRODUCT_PRICES = '/[creatorSlug]/[productSlug]/prices',
  PRODUCT_PURCHASE = '/[creatorSlug]/[productSlug]/purchase', // old version
  PRODUCT_REVIEWS = '/[creatorSlug]/[productSlug]/reviews',
  PRODUCT_COMMENTS = '/[creatorSlug]/[productSlug]/comments',
  PRODUCT_JOIN_DISCUSSION = '/[creatorSlug]/[productSlug]/join-discussion',
  PRODUCT_COMMENT_THREAD = '/[creatorSlug]/[productSlug]/thread/[commentId]',
  PURCHASE_CONFIRMATION_CART = '/[creatorSlug]/purchase-confirmation',
  PURCHASE_CONFIRMATION_PRODUCT = '/[creatorSlug]/purchase-confirmation?productId=[productId]',
  RESPONSES_ALL = '/all-responses',
  SALES = '/sales',
  SALES_ALL = '/all-sales',
  DASHBOARD_SELECT_TEAM = '/dashboard/select-team',
  START_CHATTING = '/[creatorSlug]/start-chatting',
  CREATOR_MESSAGING_TERMS = '/[creatorSlug]/messaging-terms-and-conditions',
  SUPPORT = '/support',
  USER_PROFILE = '/[creatorSlug]/user-profile',
  USER_PROFILE_ADD_PAYMENT_METHOD = '/[creatorSlug]/user-profile/add-payment-method',
  USER_PROFILE_EDIT = '/[creatorSlug]/user-profile/edit',
  USER_PROFILE_CHAT = '/[creatorSlug]/user-profile/chat',
  USER_PROFILE_LIBRARY = '/[creatorSlug]/user-profile/library',
  USER_PROFILE_BOOKMARKS = '/[creatorSlug]/user-profile/bookmarks',
  USER_PROFILE_COMMUNITY = '/[creatorSlug]/user-profile/community',
  USER_PROFILE_BOOKINGS = '/[creatorSlug]/user-profile/bookings',
  USER_PROFILE_ACTIVITY = '/[creatorSlug]/user-profile/activity',
  USER_PROFILE_ACTIVITY_DETAILS = '/[creatorSlug]/user-profile/activity/[userWorkflowId]',
  USER_SUBSCRIPTION_ID = '/[creatorSlug]/user-profile/subscription/[subscriptionId]',
  WIDGET = '/[creatorSlug]/widget',
  WAITLIST_SIGN_UP = '/waitlist/sign-up',
  WAITLIST_SUCCESS = '/waitlist/success',
  LANDING_WORKFLOW_TEMPLATES_LANDING = '/workflow-templates/[slug]',
}

export type IdentifierParams<I> =
  I extends IDENTIFIERS.ADMIN_AFFILIATE_LIST ? NoRequiredParams
  : I extends IDENTIFIERS.ADMIN_AFFILIATE_LIST ? NoRequiredParams
  : I extends IDENTIFIERS.ADMIN_CREATORS_SLUG ? RequiredParams<'creatorSlug'>
  : I extends IDENTIFIERS.ADMIN_CREATORS ? NoRequiredParams
  : I extends IDENTIFIERS.ADMIN_FAQS_LIST ? NoRequiredParams
  : I extends IDENTIFIERS.ADMIN_PRODUCTS_LIST ? NoRequiredParams
  : I extends IDENTIFIERS.ADMIN_PRODUCTS_TYPES ? NoRequiredParams
  : I extends IDENTIFIERS.ADMIN_SIGN_IN ? NoRequiredParams
  : I extends IDENTIFIERS.ADMIN_TAGS_LIST ? NoRequiredParams
  : I extends IDENTIFIERS.ADMIN_TESTIMONIALS_LIST ? NoRequiredParams
  : I extends IDENTIFIERS.AGENCY_DASHBOARD ? NoRequiredParams
  : I extends IDENTIFIERS.AGENCY_DASHBOARD_TEAM ? NoRequiredParams
  : I extends IDENTIFIERS.AGENCY_ONBOARDING ? NoRequiredParams
  : I extends IDENTIFIERS.AUTH_FORGOT_PASSWORD ? NoRequiredParams
  : I extends IDENTIFIERS.AUTH_RESET_PASSWORD ? NoRequiredParams
  : I extends IDENTIFIERS.AUTH_SIGN_IN ? NoRequiredParams
  : I extends IDENTIFIERS.AUTH_SIGN_UP ? NoRequiredParams
  : I extends IDENTIFIERS.BROADCAST_LIBRARY ? NoRequiredParams
  : I extends IDENTIFIERS.CART_CHECKOUT ? RequiredParams<'creatorSlug'>
  : I extends IDENTIFIERS.CART ? RequiredParams<'creatorSlug'>
  : I extends IDENTIFIERS.CHAT_WITH_CREATOR ? RequiredParams<'creatorSlug'>
  : I extends IDENTIFIERS.COLLECTION ? RequiredParams<'creatorSlug' | 'collectionSlug'>
  : I extends IDENTIFIERS.COMPONENTS ? NoRequiredParams
  : I extends IDENTIFIERS.CONNECT ? RequiredParams<'creatorSlug'>
  : I extends IDENTIFIERS.CONVERSATION_SID ? RequiredParams<'conversationSid'>
  : I extends IDENTIFIERS.CREATOR_MESSAGING_TERMS ? RequiredParams<'creatorSlug'>
  : I extends IDENTIFIERS.CREATOR_ONBOARDING_ATTORNEY ? NoRequiredParams
  : I extends IDENTIFIERS.CREATOR_ONBOARDING_CLAIM_SLUG ? NoRequiredParams
  : I extends IDENTIFIERS.CREATOR_ONBOARDING_START_INITIAL_PRODUCT_CREATION ? NoRequiredParams
  : I extends IDENTIFIERS.CREATOR_ONBOARDING_AI_AGENT_SETUP ? NoRequiredParams
  : I extends IDENTIFIERS.CREATOR_ONBOARDING_TERMS_OF_SERVICE ? NoRequiredParams
  : I extends IDENTIFIERS.CREATOR_ONBOARDING_VERIFY_URL ? NoRequiredParams
  : I extends IDENTIFIERS.CREATOR_POPOVER_WIDGET_HOME ? RequiredParams<'creatorSlug'>
  : I extends IDENTIFIERS.CREATOR_ONBOARDING_IN_PERSON_SESSIONS ? NoRequiredParams
  : I extends IDENTIFIERS.CREATOR_ONBOARDING_ONLINE_SESSIONS ? NoRequiredParams
  : I extends IDENTIFIERS.CREATOR_ONBOARDING_CHAT ? NoRequiredParams
  : I extends IDENTIFIERS.CREATOR_ONBOARDING_MEMBERSHIP ? NoRequiredParams
  : I extends IDENTIFIERS.CREATOR_ONBOARDING_CREATE_SERVICE ? NoRequiredParams
  : I extends IDENTIFIERS.CREATOR_ONBOARDING_CREATE_PRICE? NoRequiredParams
  : I extends IDENTIFIERS.CREATOR_ONBOARDING_CREATE_TIER? NoRequiredParams
  : I extends IDENTIFIERS.CREATOR_ONBOARDING_SUCCESS ? NoRequiredParams
  : I extends IDENTIFIERS.CREATOR_SIGN_IN ? NoRequiredParams
  : I extends IDENTIFIERS.CREATOR_SIGN_UP ? RequiredParams<'slug'>
  : I extends IDENTIFIERS.CREATOR_STORIES ? NoRequiredParams
  : I extends IDENTIFIERS.CREATOR_STORY_SLUG ? RequiredParams<'creatorSlug'>
  : I extends IDENTIFIERS.CREATOR_TERMS ? NoRequiredParams
  : I extends IDENTIFIERS.CREATOR ? RequiredParams<'creatorSlug'>
  : I extends IDENTIFIERS.DASHBOARD_ACCOUNT ? NoRequiredParams
  : I extends IDENTIFIERS.DASHBOARD_ADD_TAGS ? RequiredParams<'productId'>
  : I extends IDENTIFIERS.DASHBOARD_ADD_WELCOME_TEXT ? RequiredParams<'productId'>
  : I extends IDENTIFIERS.DASHBOARD_AI_FOLLOWUP_HISTORY ? NoRequiredParams
  : I extends IDENTIFIERS.DASHBOARD_CHATS_LIST ? NoRequiredParams
  : I extends IDENTIFIERS.DASHBOARD_CHAT_WITH_CREATOR ? RequiredParams<'creatorSlug'>
  : I extends IDENTIFIERS.DASHBOARD_CHAT_PRODUCT_SETTINGS ? RequiredParams<'productSlug'>
  : I extends IDENTIFIERS.DASHBOARD_EXAMPLES ? NoRequiredParams
  : I extends IDENTIFIERS.DASHBOARD_CONVERSATIONS ? NoRequiredParams
  : I extends IDENTIFIERS.DASHBOARD_CONVERSATION ? RequiredParams<'conversationSid'>
  : I extends IDENTIFIERS.DASHBOARD_COMMENTS ? NoRequiredParams
  : I extends IDENTIFIERS.DASHBOARD_COMMENT_DETAILS ? RequiredParams<'id'>
  : I extends IDENTIFIERS.DASHBOARD_BYTES ? NoRequiredParams
  : I extends IDENTIFIERS.DASHBOARD_BROADCASTS ? NoRequiredParams
  : I extends IDENTIFIERS.DASHBOARD_BROADCAST_DETAILS ? RequiredParams<'broadcastId'>
  : I extends IDENTIFIERS.DASHBOARD_BROADCAST_CREATE ? NoRequiredParams
  : I extends IDENTIFIERS.DASHBOARD_WIDGET ? NoRequiredParams
  : I extends IDENTIFIERS.DASHBOARD_MEMBERS ? NoRequiredParams
  : I extends IDENTIFIERS.DASHBOARD_SCRIPTS ? NoRequiredParams
  : I extends IDENTIFIERS.DASHBOARD_WORKFLOWS_TEMPLATES ? NoRequiredParams
  : I extends IDENTIFIERS.DASHBOARD_IMPORT_CONTACTS ? NoRequiredParams
  : I extends IDENTIFIERS.DASHBOARD_MEMBER ? RequiredParams<'id'>
  : I extends IDENTIFIERS.DASHBOARD_SCRIPT ? RequiredParams<'id'>
  : I extends IDENTIFIERS.DASHBOARD_WORKFLOWS_TEMPLATES_DETAIL ? RequiredParams<'id'>
  : I extends IDENTIFIERS.DASHBOARD_WORKFLOWS_WORKFLOW_TRACKER ? RequiredParams<'document-request-user' | 'custom-terms' | 'form-request' | 'invoice'>
  : I extends IDENTIFIERS.DASHBOARD_MEMBERSHIP ? NoRequiredParams
  : I extends IDENTIFIERS.DASHBOARD_MESSAGING_FEED ? NoRequiredParams
  : I extends IDENTIFIERS.DASHBOARD_PREVIEW ? NoRequiredParams
  : I extends IDENTIFIERS.DASHBOARD_PRODUCT_TERMS ? RequiredParams<'productId'>
  : I extends IDENTIFIERS.DASHBOARD_SERVICES ? NoRequiredParams
  : I extends IDENTIFIERS.DASHBOARD_SERVICES_DETAILS ? RequiredParams<'productSlug'>
  : I extends IDENTIFIERS.DASHBOARD_CONTENT ? NoRequiredParams
  : I extends IDENTIFIERS.DASHBOARD_TRANSACTIONS ? NoRequiredParams
  : I extends IDENTIFIERS.DASHBOARD_TRANSACTIONS_PAYMENTS ? NoRequiredParams
  : I extends IDENTIFIERS.DASHBOARD_TRANSACTIONS_PAYOUTS ? NoRequiredParams
  : I extends IDENTIFIERS.DASHBOARD_TRANSACTIONS_INVOICES ? NoRequiredParams
  : I extends IDENTIFIERS.DASHBOARD_TRANSACTIONS_OVERVIEW ? NoRequiredParams
  : I extends IDENTIFIERS.DASHBOARD_TERMS_TEMPLATES ? NoRequiredParams
  : I extends IDENTIFIERS.DASHBOARD_TERMS_TEMPLATES_EDIT ? RequiredParams<'id'>
  : I extends IDENTIFIERS.DASHBOARD_DOCUMENT_REQUEST_TEMPLATES ? NoRequiredParams
  : I extends IDENTIFIERS.DASHBOARD_DOCUMENT_REQUEST_TEMPLATES_EDIT ? RequiredParams<'id'>
  : I extends IDENTIFIERS.DASHBOARD_FORM_REQUESTS ? NoRequiredParams
  : I extends IDENTIFIERS.DASHBOARD_FORM_REQUESTS_EDIT ? RequiredParams<'id'>
  : I extends IDENTIFIERS.DASHBOARD_QUESTIONNAIRES ? NoRequiredParams
  : I extends IDENTIFIERS.DASHBOARD_QUESTIONNAIRES_EDIT ? RequiredParams<'id'>
  : I extends IDENTIFIERS.DASHBOARD_INVOICE_REQUESTS ? NoRequiredParams
  : I extends IDENTIFIERS.DASHBOARD_INVOICE_REQUESTS_EDIT ? RequiredParams<'id'>
  : I extends IDENTIFIERS.DASHBOARD_TEAM ? NoRequiredParams
  : I extends IDENTIFIERS.DASHBOARD_USER_WORKFLOWS ? NoRequiredParams
  : I extends IDENTIFIERS.DASHBOARD_USER_WORKFLOW_DETAIL ? RequiredParams<'userWorkflowId'>
  : I extends IDENTIFIERS.DASHBOARD_USER_WORKFLOW_DOCUMENTS ? RequiredParams<'userWorkflowId'>
  : I extends IDENTIFIERS.DASHBOARD_WORKFLOWS ? NoRequiredParams
  : I extends IDENTIFIERS.DASHBOARD_WORKFLOWS_ATTACHMENTS ? NoRequiredParams
  : I extends IDENTIFIERS.DASHBOARD_WORKFLOWS_MY_WORKFLOWS ? NoRequiredParams
  : I extends IDENTIFIERS.DASHBOARD_WORKFLOWS_MY_WORKFLOWS_DETAILS ? RequiredParams<'workflowId'>
  : I extends IDENTIFIERS.DASHBOARD_WORKFLOWS_MY_WORKFLOWS_PRODUCT ? RequiredParams<'productId'>
  : I extends IDENTIFIERS.DASHBOARD_INBOX ? NoRequiredParams
  : I extends IDENTIFIERS.DASHBOARD_INBOX_ITEM ? RequiredParams<'id'>
  : I extends IDENTIFIERS.DASHBOARD_BOOKINGS ? NoRequiredParams
  : I extends IDENTIFIERS.DASHBOARD_BOOKINGS_ITEM ? RequiredParams<'id'>
  : I extends IDENTIFIERS.DASHBOARD_ACCOUNT_BILLING ? NoRequiredParams
  : I extends IDENTIFIERS.DASHBOARD_ACCOUNT_SMS_FOLLOW_UPS ? NoRequiredParams
  : I extends IDENTIFIERS.DASHBOARD_ACCOUNT_EDIT_PROFILE ? NoRequiredParams
  : I extends IDENTIFIERS.DASHBOARD_ACCOUNT_ATTORNEY_INFO ? NoRequiredParams
  : I extends IDENTIFIERS.DASHBOARD_ACCOUNT_EDIT_BUSINESS_DETAILS ? NoRequiredParams
  : I extends IDENTIFIERS.DASHBOARD_ACCOUNT_INTEGRATIONS ? NoRequiredParams
  : I extends IDENTIFIERS.DASHBOARD_ACCOUNT_AI_AGENT ? NoRequiredParams
  : I extends IDENTIFIERS.WIDGET ? RequiredParams<'creatorSlug'>
  : I extends IDENTIFIERS.DASHBOARD ? NoRequiredParams
  : I extends IDENTIFIERS.DASHBOARD ? NoRequiredParams
  : I extends IDENTIFIERS.HELP ? NoRequiredParams
  : I extends IDENTIFIERS.HOME ? NoRequiredParams
  : I extends IDENTIFIERS.INITIATE_WORKFLOW_WITH_CTA ? RequiredParams<'creatorSlug' | 'stepId'>
  : I extends IDENTIFIERS.LOGIN ? NoRequiredParams
  : I extends IDENTIFIERS.LOGOUT ? NoRequiredParams
  : I extends IDENTIFIERS.PAGE_401 ? NoRequiredParams
  : I extends IDENTIFIERS.PRIVACY ? NoRequiredParams
  : I extends IDENTIFIERS.PRODUCT_COMMENT_THREAD ? RequiredParams<'creatorSlug' | 'productSlug'>
  : I extends IDENTIFIERS.PRODUCT_COMMENTS ? RequiredParams<'creatorSlug' | 'productSlug'>
  : I extends IDENTIFIERS.PRODUCT_JOIN_DISCUSSION ? RequiredParams<'creatorSlug' | 'productSlug'>
  : I extends IDENTIFIERS.PRODUCT_PRICES ? RequiredParams<'creatorSlug' | 'productSlug'>
  : I extends IDENTIFIERS.PRODUCT_PURCHASE ? RequiredParams<'creatorSlug' | 'productSlug'>
  : I extends IDENTIFIERS.PRODUCT_REVIEWS ? RequiredParams<'creatorSlug' | 'productSlug'>
  : I extends IDENTIFIERS.PRODUCT ? RequiredParams<'creatorSlug' | 'productSlug'>
  : I extends IDENTIFIERS.PRODUCT_TYPE ? RequiredParams<'creatorSlug' | 'productTypeSlug'>
  : I extends IDENTIFIERS.PURCHASE_CONFIRMATION_CART ? RequiredParams<'creatorSlug' | 'cartId'>
  : I extends IDENTIFIERS.PURCHASE_CONFIRMATION_PRODUCT ? RequiredParams<'creatorSlug' | 'productId'>
  : I extends IDENTIFIERS.RESPONSES_ALL ? NoRequiredParams
  : I extends IDENTIFIERS.SALES_ALL ? NoRequiredParams
  : I extends IDENTIFIERS.SALES ? NoRequiredParams
  : I extends IDENTIFIERS.DASHBOARD_SELECT_TEAM ? NoRequiredParams
  : I extends IDENTIFIERS.START_CHATTING ? RequiredParams<'creatorSlug'>
  : I extends IDENTIFIERS.SUPPORT ? NoRequiredParams
  : I extends IDENTIFIERS.USER_PROFILE_ADD_PAYMENT_METHOD ? RequiredParams<'creatorSlug'>
  : I extends IDENTIFIERS.USER_PROFILE_EDIT ? RequiredParams<'creatorSlug'>
  : I extends IDENTIFIERS.USER_PROFILE ? RequiredParams<'creatorSlug'>
  : I extends IDENTIFIERS.USER_PROFILE_CHAT ? RequiredParams<'creatorSlug'>
  : I extends IDENTIFIERS.USER_PROFILE_LIBRARY ? RequiredParams<'creatorSlug'>
  : I extends IDENTIFIERS.USER_PROFILE_BOOKMARKS ? RequiredParams<'creatorSlug'>
  : I extends IDENTIFIERS.USER_PROFILE_COMMUNITY ? RequiredParams<'creatorSlug'>
  : I extends IDENTIFIERS.USER_PROFILE_BOOKINGS ? RequiredParams<'creatorSlug'>
  : I extends IDENTIFIERS.USER_PROFILE_ACTIVITY ? RequiredParams<'creatorSlug'>
  : I extends IDENTIFIERS.USER_PROFILE_ACTIVITY_DETAILS ? RequiredParams<'creatorSlug' | 'userWorkflowId'>
  : I extends IDENTIFIERS.USER_SUBSCRIPTION_ID ? RequiredParams<'creatorSlug' | 'subscriptionId'>
  : I extends IDENTIFIERS.WAITLIST_SIGN_UP ? NoRequiredParams
  : I extends IDENTIFIERS.WAITLIST_SUCCESS ? NoRequiredParams
  : I extends IDENTIFIERS.DASHBOARD_EDIT_TIER ? NoRequiredParams
  : I extends IDENTIFIERS.DOCUMENT_BY_ID ? RequiredParams<'creatorSlug' | 'documentId'>
  : I extends IDENTIFIERS.DOCUMENT_REQUEST_DOCUMENT_PREVIEW ? RequiredParams<'documentRequestUserFileId' | 'creatorSlug'>
  : I extends IDENTIFIERS.DOCUMENT_REQUEST_USER_DETAIL ? RequiredParams<'documentRequestUserId' | 'creatorSlug'>
  : I extends IDENTIFIERS.DOCUMENT_REQUEST_USER_FILE_DETAIL ? RequiredParams<'documentRequestUserId' | 'creatorSlug' | 'documentRequestUserFileId'>
  : I extends IDENTIFIERS.FORM_REQUEST_DETAILS ? RequiredParams<'formRequestId' | 'creatorSlug'>
  : I extends IDENTIFIERS.INVOICE_DETAIL ? RequiredParams<'invoiceId'>
  : I extends IDENTIFIERS.PAY_INVOICE ? RequiredParams<'creatorSlug' | 'invoiceId'>
  : I extends IDENTIFIERS.CUSTOM_TERMS_DETAILS ? RequiredParams<'creatorSlug' | 'customTermsId'>
  : I extends IDENTIFIERS.CREATOR_ONBOARDING_SELECT_WORKFLOWS ? NoRequiredParams
  : I extends IDENTIFIERS.CREATOR_ONBOARDING_BOOK_CALL ? NoRequiredParams
  : I extends IDENTIFIERS.LANDING_WORKFLOW_TEMPLATES_LANDING ? RequiredParams<'slug'>
  : [never];
